






































import Component, {mixins} from 'vue-class-component';
import dataFormat from '@/shared/utils/dataFormat';
import { Prop } from 'vue-property-decorator';

@Component<Product>({
  metaInfo() {
    return {
      title: 'BICENA' + ' | POING',
      meta: [
        {
          name: 'keywords',
          vmid: 'keywords',
          content: '포잉, POING, ' + '비채나'
        },
        {
          name: 'description',
          vmid: 'description',
          content: '비채나'
        },
        {
          name: 'url',
          property: 'og:url',
          vmid: 'og:url',
          content: window.location.href
        },
        {
          name: 'title',
          property: 'og:title',
          vmid: 'og:title',
          content: '비채나' + ' | POING'
        },
        {
          name: 'description',
          property: 'og:description',
          vmid: 'og:description',
          content: '비채나'
        }
      ]
    }
  }
})
export default class Product extends mixins(dataFormat) {
  @Prop()
  public selectLang!: any;
}
